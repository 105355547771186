.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 10px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-dot {
  width: 10px;
  height: 10px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: white;
  animation: loading 1s ease-in-out infinite;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-dot:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1, 3);
  }
  40% {
    transform: scale(1.5);
  }
}

.loading-message {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  animation: blink 1s infinite;
}

.loading-line {
  width: 40px;
  height: 2px;
  margin-top: 5px;
  background-color: white;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
